import { DWH_EXPORT_EVENTS } from '~/constants'
import { Button, DialogBody, DialogContainer, DialogFooter, DialogHeader, Interpolate } from '@wg/wows-react-uikit'
import classnames from 'classnames'
import get from 'lodash/get'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { setPredictProgress, setUsedStarters } from '~/Actions/ActionApp'
import { buyBoosters } from '~/Actions/ActionBuy'
import { closeMultipleBuyDialog, openPurchaseConfirmation } from '~/Actions/ActionDialogs'
import Currency from '~/Components/Currency/Currency'
import RangeRewards from '~/Components/RangeRewards/RangeRewards'
import RewardShip from '~/Components/RewardShip/RewardShip'
import Slider, { SliderLabels } from '~/Components/Slider/Slider'
import WidthWrapper from '~/Components/WidthWrapper/WidthWrapper'
import { i18n_Props } from '~/i18n'
import { State } from '~/Reducers'
import { IBooster, IReward } from '~/Reducers/ReducerApp'
import styles from './MultipleBuyDialog.scss'
import dwhExport from '~/api/dwhExport'
import Count from '~/Components/Count/Count'
import StartBoosters from '~/Components/StartBoosters/StartBoosters'
import WreathIcon from '~/Components/Dialogs/MultipleBuyDialog/images/WreathIcon'
import { isEqual } from 'lodash'
import Text from '~/Components/Text/Text'
import BuyGoldButton from '~/Components/BuyGoldButton/BuyGoldButton'
import { gold as wowsEntityGold } from '~/wowsEntities'
import WarnIcon from '~/Components/Dialogs/MultipleBuyDialog/images/WarnIcon'
import BuyIcon from '~/Components/Dialogs/MultipleBuyDialog/images/BuyIcon'
import Wallet from '~/Components/Wallet/Wallet'
import { isInIframe } from '~/utils'
import InfoMessage from '~/Components/Dialogs/MultipleBuyDialog/InfoMessage'
import preloaded from '~/preloaded'
import classNames from 'classnames'

type OwnProps = i18n_Props

type UpdateCardType = {
    usedBoosters?: Array<string>
    newProgress: number
}

interface DispatchProps {
    closeMultipleBuyDialog: () => void
    buyBoosters: (isPremium: boolean) => void
    setPredictProgress: (predictProgress: number) => void
    setUsedStarters: (usedStarters: Array<string>) => void
    openPurchaseConfirmation: (price: number, rewards: Array<string>) => void
}

interface StateProps {
    predictProgress: number
    isMultipleBuyDialogVisible: boolean
    boosters: Array<IBooster>
    premiumBooster: IBooster
    progress: number
    blueprintsCount: number
    gold: number
    rewards: Array<IReward>
    mainRewardShipId: string
    starters: Array<IBooster>
    commonStartersLevels: number
    startersAvailable: boolean
    usedBoosters: Array<string>
}

export type Props = StateProps & OwnProps & DispatchProps

const COUNTER_ROW_ID = 'multiple-buy-counter-row'

class MultipleBuyDialog extends Component<Props> {
    shouldComponentUpdate(nextProps: Props) {
        return (
            this.props.predictProgress != nextProps.predictProgress ||
            this.props.isMultipleBuyDialogVisible != nextProps.isMultipleBuyDialogVisible ||
            this.props.boosters.length != nextProps.boosters.length ||
            (this.props.premiumBooster && this.props.premiumBooster.id) != (nextProps.premiumBooster && nextProps.premiumBooster.id) ||
            this.props.progress != nextProps.progress ||
            this.props.gold != nextProps.gold ||
            this.props.rewards.length != nextProps.rewards.length
        )
    }

    componentWillUnmount() {
        this.props.setUsedStarters([])
    }

    onCounterChange = (value: number) => {
        if (!this.props.startersAvailable || value <= 0) {
            this.props.setPredictProgress(value >= 0 ? value : 0)
            this.props.setUsedStarters([])
            return
        }
        const biggestStarter = this.props.starters[0]
        const biggestStarterProgress = biggestStarter.bonus.shipLevel
        let usedBoosters = [...this.props.usedBoosters]
        this.props.starters.map((starter) => {
            const alreadyAdded = this.props.usedBoosters.includes(starter.id)
            const count = starter.bonus.shipLevel
            const isBigBooster = starter.id === biggestStarter.id
            if (
                !alreadyAdded &&
                value >= count &&
                (isBigBooster || value >= this.props.commonStartersLevels || value === count || (!isBigBooster && value < biggestStarterProgress))
            ) {
                usedBoosters.push(starter.id)
            }
            if (
                (value >= biggestStarterProgress && value < this.props.commonStartersLevels && alreadyAdded && !isBigBooster) ||
                (alreadyAdded && value < starter.bonus.shipLevel)
            ) {
                usedBoosters = usedBoosters.filter((item) => item !== starter.id)
            }
        })
        if (!isEqual(usedBoosters, this.props.usedBoosters)) {
            this.props.setUsedStarters(usedBoosters)
        }
        this.props.setPredictProgress(value)
    }

    updateCardsInfo = (count: number, id: string, withoutSum: boolean): UpdateCardType => {
        let usedBoosters: Array<string> = []
        const newProgress = this.props.predictProgress + count
        if (count >= 0) {
            usedBoosters = [...this.props.usedBoosters, id]
        } else {
            usedBoosters = this.props.usedBoosters.filter((item) => item != id)
        }
        return { usedBoosters, newProgress }
    }

    onBoosterSelect = (count: number, id: string, priceDiff: number, withoutSum = false) => {
        const updateInfo = this.updateCardsInfo(count, id, withoutSum)
        this.props.setPredictProgress(updateInfo.newProgress)
        this.props.setUsedStarters(updateInfo.usedBoosters)
    }

    onSliderChange = (value: number) => {
        dwhExport.send(DWH_EXPORT_EVENTS.SET_MULTIPLE_BOOSTER_BUY_AMOUNT, {
            amount: value,
        })
    }

    getPrice = () => {
        const boosterPrice = this.props.premiumBooster ? this.props.premiumBooster.price : 0
        return boosterPrice * this.props.predictProgress
    }

    getCommonSale = () => {
        const commonPrice = this.props.predictProgress * this.props.premiumBooster.price
        const usedStarters = this.props.starters.filter((starter) => this.props.usedBoosters.includes(starter.id))
        return commonPrice - usedStarters?.reduce((sum: number, a) => sum + a.bonus.shipLevel * this.props.premiumBooster.price - a.price, 0)
    }

    getMaxValue = () => {
        const price = this.props.premiumBooster ? this.props.premiumBooster.price : 0
        const left = this.props.rewards.length - this.props.progress
        let max = parseInt((this.props.gold / price).toFixed(0))
        if (max > left) {
            max = left
        }
        if (max === 0) {
            max++
        }
        return max
    }

    onSliderRewardClick = () => {
        this.onCounterChange(this.props.rewards.length - this.props.progress)
    }

    render() {
        const { t, gold } = this.props
        const price = this.getPrice()
        const commonSale = this.getCommonSale()
        const salePrice = commonSale > 0 ? commonSale : 0
        const hasSale = price !== salePrice
        const sale = Math.floor(100 - (100 * salePrice / price))
        const notEnoughMoney = gold < price && gold < salePrice
        const needGold = salePrice - gold
        const approveButtonStyle = classnames(styles.buyButton, {
            [styles.disableFocus]: needGold,
        })
        const sliderLabels: SliderLabels = []
        const isMainRewardHighlighted = this.props.rewards.length === this.props.progress + this.props.predictProgress

        const sliderReward = classnames(styles.sliderReward, {
            [styles.sliderRewardActive]: isMainRewardHighlighted,
        })
        const goldColor = '#FFCC66'
        const greyColor = '#919298'
        const isFrame = isInIframe()

        this.props.rewards.forEach((reward, index) => {
            const hasWreath = reward.grade !== 'normal'
            if (hasWreath && reward.icon) {
                const color = this.props.predictProgress + this.props.progress >= reward.shipLevelNumber ? goldColor : greyColor
                sliderLabels.push({
                    index: reward.shipLevelNumber,
                    content: (
                        <div className={styles.wreath}>
                            <WreathIcon color={color} className={styles.wreathWrapper} />
                            <img src={reward.icon} className={styles.icon} alt={'reward'} />
                            <div
                                className={styles.wreathLabel}
                                style={{
                                    color: color,
                                }}
                            >
                                {reward.shipLevelNumber}
                            </div>
                        </div>
                    ),
                })
            }
        })

        const priceWrapperClassnames = classNames(styles.priceWrapper, {
            [styles.withSale]: hasSale,
        })

        return (
            <React.Fragment>
                {!isFrame && <Wallet withBlur className={styles.wallet} />}
                <DialogContainer>
                    {!isFrame && preloaded.settings.isBuyGoldButtonVisible && <DialogHeader>{t('Купить этапы')}</DialogHeader>}
                    <DialogBody>
                        <WidthWrapper className={styles.widthWrapper}>
                            <div className={styles.messageWrapper}>
                                <div className={styles.message}>
                                    {t(
                                        'Вы можете ускорить процесс строительства за дублоны, сразу получить награды и заполнить шкалу прогресса строительства.',
                                    )}
                                </div>
                                {this.props.startersAvailable && (
                                    <div className="subTitleWithClock">
                                        <Text str={'До окончания скидки: %(startersTimeLimit)s'} />
                                    </div>
                                )}
                            </div>
                            {this.props.startersAvailable && (
                                <StartBoosters
                                    onClick={this.onBoosterSelect}
                                    selectedStarters={this.props.usedBoosters}
                                    progress={this.props.progress + this.props.predictProgress}
                                    levelsCount={this.props.rewards.length}
                                />
                            )}
                            <div className={styles.sliderWrapper}>
                                <Slider
                                    min={0}
                                    max={this.props.rewards.length}
                                    minValue={0}
                                    value={this.props.predictProgress}
                                    progress={this.props.progress}
                                    labels={sliderLabels}
                                    onDrag={this.onCounterChange}
                                    onChange={this.onSliderChange}
                                    blueprintsCount={this.props.blueprintsCount}
                                    after={
                                        <div className={sliderReward} onClick={this.onSliderRewardClick}>
                                            <RewardShip
                                                id={this.props.mainRewardShipId}
                                                fixedTooltip={true}
                                                raysVisible={isMainRewardHighlighted}
                                                reward={this.props.rewards[this.props.rewards.length - 1]}
                                            />
                                        </div>
                                    }
                                />
                            </div>
                            <div className={styles.rewardsWrapper}>
                                <RangeRewards
                                    from={this.props.progress}
                                    to={this.props.progress + this.props.predictProgress}
                                    filteredShips={[this.props.mainRewardShipId]}
                                    isLastIncluded={this.props.rewards.length === this.props.progress + this.props.predictProgress}
                                />
                            </div>
                        </WidthWrapper>
                    </DialogBody>
                    <DialogFooter className={styles.footerHeight}>
                        <div className={styles.counter} id={COUNTER_ROW_ID}>
                            <div className={styles.counterContent}>
                                <div className={styles.levelsWrapper}>
                                    <div className={styles.levels}>{'Количество:'}</div>
                                    <Count
                                        value={this.props.predictProgress}
                                        min={0}
                                        max={this.props.rewards.length - this.props.progress}
                                        onChange={(value: number) => {
                                            this.onCounterChange(value)
                                            this.onSliderChange(value)
                                        }}
                                    />
                                </div>
                                <div className={styles.buyAllWrapper} onClick={this.onSliderRewardClick}>
                                    <Interpolate
                                        str={t('Все этапы, %(count)s шт.')}
                                        count={this.props.rewards.length - this.props.progress}
                                        className={styles.buyAll}
                                    />
                                </div>
                                <div className={priceWrapperClassnames}>
                                    <div className={styles.levels}>{'Стоимость:'}</div>
                                    <div className={'priceBlock'}>
                                        {hasSale && <div className={'sale'}>{sale}%</div>}
                                        <div className={'price'}>
                                            {hasSale && <div className={'oldPrice'}>{wowsEntityGold(price || 0, false, true, 'oldPrice')}</div>}
                                            <div className={'newPrice'}>
                                                <Currency amount={salePrice} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!notEnoughMoney ? (
                            <div className={approveButtonStyle}>
                                <Button
                                    type={'orange'}
                                    className={classNames(!this.props.predictProgress && styles.disabledButton)}
                                    isFlat={true}
                                    isLarger={true}
                                    isDisabled={!this.props.predictProgress}
                                    onClick={() => {
                                        this.props.openPurchaseConfirmation(
                                            salePrice,
                                            this.props.rewards.map((reward) => {
                                                if (
                                                    reward.shipLevelNumber <= this.props.predictProgress + this.props.progress &&
                                                    reward.shipLevelNumber > this.props.progress
                                                )
                                                    return reward.id
                                            }),
                                        )
                                    }}
                                >
                                    {t('Купить')}
                                </Button>
                            </div>
                        ) : (
                            <div className={styles.needGoldWrapper}>
                                <div className={styles.countToBuy}>
                                    <WarnIcon />
                                    <Interpolate
                                        str={t('Не хватает: %(notEnough)s')}
                                        notEnough={wowsEntityGold(needGold, false)}
                                        className={styles.text}
                                    />
                                </div>
                                <div className={styles.needGoldCommon}>
                                    <BuyIcon />
                                    <BuyGoldButton />
                                </div>
                            </div>
                        )}
                        <InfoMessage
                            predictProgress={this.props.predictProgress}
                            starters={this.props.starters}
                            commonStartersLevels={this.props.commonStartersLevels}
                            singlePrice={this.props.premiumBooster.price}
                        />
                    </DialogFooter>
                </DialogContainer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
    const boosters = state.ReducerApp.boosters.filter((booster) => booster.isPremium)
    const progress = state.ReducerApp.account.progress
    const maxProgress = state.ReducerApp.rewards.length - progress
    const startersAllowed = state.ReducerApp.startersTimeLimit > Date.now()
    const starters = startersAllowed
        ? boosters
            .filter(
                (booster) =>
                    booster.bonus.shipLevel > 1 &&
                    !state.ReducerApp.account.boughtBoostersIds.includes(booster.id) &&
                    booster.bonus.shipLevel <= maxProgress,
            )
            ?.sort((a, b) => b.bonus.shipLevel - a.bonus.shipLevel)
        : []
    const gold = get(state.ReducerApp, 'account.wallet.gold', 0) as number
    const premiumBooster = state.ReducerApp.boosters.filter((booster) => booster.isPremium && !booster.unique)[0]

    return {
        gold: gold,
        premiumBooster: premiumBooster,
        boosters: boosters,
        predictProgress: state.ReducerApp.predictProgress,
        progress: progress,
        blueprintsCount: state.ReducerApp.blueprintsCount,
        isMultipleBuyDialogVisible: state.ReducerDialogs.isMultipleBuyDialogVisible,
        mainRewardShipId: state.ReducerApp.mainRewardShipId,
        rewards: state.ReducerApp.rewards,
        starters: starters,
        commonStartersLevels: starters.reduce((sum, a) => sum + a.bonus.shipLevel, 0),
        startersAvailable: state.ReducerApp.startersTimeLimit > Date.now() && starters.length > 0,
        usedBoosters: state.ReducerApp.usedStarters,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<State, unknown, AnyAction>, _: OwnProps): DispatchProps => {
    return {
        buyBoosters: (isPremium: boolean) => {
            dispatch(buyBoosters(isPremium))
        },
        closeMultipleBuyDialog: () => {
            dispatch(closeMultipleBuyDialog())
        },
        setPredictProgress: (predictProgress: number) => {
            dispatch(setPredictProgress(predictProgress))
        },
        setUsedStarters: (usedStarters: Array<string>) => {
            dispatch(setUsedStarters(usedStarters))
        },
        openPurchaseConfirmation: (price: number, rewards: Array<string>) => {
            dispatch(openPurchaseConfirmation(price, rewards))
        },
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MultipleBuyDialog))
